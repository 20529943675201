<template>
    <div class="bg-white">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
                <h1 class="h4 mb-0">Orders Requiring Attention</h1>
            </div>
        </div>
        <ul v-if="orderData && orderData.data.length" class="order-list mb-4">
            <li class="header-row">
                <div class="row align-items-center">
                    <div class="col-8 col-md-5">
                        <div class=""><strong>Patient &amp; Dentist</strong></div>
                    </div>
                    <div class="col-4 d-none d-md-block">
                        <div class=""><strong>Issue</strong></div>
                    </div>
                    <div class="col-2 d-none d-md-block">
                        <div class=""><strong>Order date</strong></div>
                    </div>
                    <div class="col-1">
                        <div></div>
                    </div>
                </div>
            </li>
            <li v-for="order of orderData.data" v-bind:key="order.id">
                <div class="row align-items-center">
                    <div class="col-8 col-md-5">
                        <div class=""><strong>{{ order.patient_name }}</strong></div>
                        <div class="">{{ order.dentist.first_name }} {{ order.dentist.surname }}</div>
                    </div>
                    <div class="col-4 d-none d-md-block">
                        <span v-if="order.tickets.length === 1">
                            {{ order.tickets[0].ticket_type_name }}
                        </span>
                        <span v-else>
                            Multiple
                        </span>
                    </div>

                    <div class="col-2 d-none d-md-block">
                        <div class="order-date">{{ $filters.formatDate(order.received_datetime) }}</div>
                    </div>

                    <div class="col-4 col-md-1">
                        <div>
                            <router-link
                                v-if="order.tickets.length > 0" 
                                class="btn btn-secondary ms-1" 
                                :to="order.tickets.length === 1 ? { name: 'TicketResolve', params: {ticket_id: order.tickets[0].id } } : { name: 'OrderTickets', params: { id: order.id} }">
                                View
                            </router-link>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div v-if="orderData && !orderData.data.length">
            No order issues found
        </div>
        <Pagination
            v-if="orderData && orderData.data.length"
            v-bind:currentPage="currentPage"
            v-bind:lastPage="lastPage"
            viewComponent="OrdersIssues"
        />
    </div>
</template>

<style scoped lang="scss">

    .order-list
    {
        list-style: none;
        padding: 0px;
        margin: 0px;

        > li
        {
            border-top: 1px solid #EEEEEE;
            padding: 10px 0px;

            strong
            {
                color: $swift-dark-grey;
            }
        }

        .header-row
        {
            border-top: 0px;
        }
    }
</style>

<script>
    import api from "@/services/api";
    import Pagination from '@/components/Pagination.vue';

    export default {
        setup () {

        },
        components: {            
            Pagination,
        },
        data() {
            return {
                orderData: null,
                patient_name: '',
                order_id: '',
            }
        },
        computed: {
            currentPage () {
                var pageParam = this.$route.query.page;
                return typeof pageParam === 'undefined' ? 1 : parseInt(pageParam);
            },
            lastPage () {
                if(this.orderData !== null)
                {
                    return parseInt(this.orderData.meta.last_page);
                }

                return null;
            },
        },
        methods: {
            fetchOrders: function() {
                if(!this.$store.state.currentPracticeId)
                {
                    this.orderData = null;
                    return;
                }
                var params = {
                    practice_id: this.$store.state.currentPracticeId,
                    page: this.currentPage,
                };
                
                api
                .get('/api/v1/ordersissues', {
                    params: params
                })
                .then((response) => {
                    this.orderData = response.data;
                })
                .catch(() => {
                    console.log('Catch orders error');
                });
            },
        },
        mounted () {
            this.fetchOrders();
        },
        watch: {
            currentPage() {
                this.fetchOrders();
            },
            '$store.state.currentPracticeId': function() {
                this.fetchOrders();
            },
            '$route.query': function() {
                this.fetchOrders();
            },
        },
    }

</script>
